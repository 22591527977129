import './App.css';
import Dvd from './components/dvd/dvd';

function App() {
  return (
    <Dvd />
  );
}

export default App;
